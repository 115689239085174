.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  margin: 20px;
  padding-bottom: 30px;
  background: var(--color-background);
  border-radius: var(--radius);

  &__image {
    margin-top: 15px;
    width: 80%;
  }

  &__item-content {
    font-size: calc(var(--font-size-md) * 1.1);
    width: 80%;
    padding: 20px;
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__item-title {
    margin: 20px 0 10px 0;
    padding: 5px;
    width: 80%;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    color: #222;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    font-size: var(--font-size-lg);

    &-social {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 0;

    & a {
      width: 50%;
    }
  }

  &__list-item {
    font-size: calc(var(--font-size-md) * 1.1);
    margin-top: 10px;
    cursor: pointer;
  }
}
