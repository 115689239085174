.contact-page {
  height: calc(100vh - 50px);
  text-align: center;
  font-size: calc(var(--base-size) * 1.6);
  padding: 30px;
  background: url('../../assets/images/contact.jpg');

  &__title {
    margin: 50px;
  }
}
