.single-post {
  flex: 9;
  margin: 0 30px;

  &__wrapper {
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 100%;
    height: 300px;
    border-radius: var(--radius);
    object-fit: cover;
  }

  &__title {
    text-align: center;
    margin: 10px;
    font-size: var(--font-size-vlg);
  }

  &__title-input {
    text-align: center;
    margin: 30px;
    font-size: var(--font-size-vlg);
    border: none;
    color: var(--color-icon);
    border-bottom: 1px solid var(--color-border);

    &:focus {
      outline: none;
    }
  }

  &__edit {
    float: right;
    font-size: var(--font-size-lg);
  }

  &__edit-icon {
    margin-left: 10px;
    cursor: pointer;

    &:first-child {
      color: var(--color-primary);
    }

    &:last-child {
      color: var(--color-danger);
    }
  }

  &__info {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-md);
    color: var(--color-primary);
  }

  &__info-author a:hover {
    color: var(--color-primary);
  }

  &__info-category a:hover {
    color: var(--color-primary);
  }

  &__description {
    font-size: var(--font-size-lg);
    line-height: 1.5;

    &:first-letter {
      margin-left: 20px;
      font-size: var(--font-size-vlg);
      font-weight: 600;
    }
  }
  &__description-input {
    font-size: var(--font-size-lg);
    line-height: 1.5;
  }

  &__btn {
    align-self: center;
    width: 75px;
    margin-top: 20px;
    cursor: pointer;
    background-color: var(--color-primary);
    border: none;
    border-radius: var(--radius);
    padding: 10px;
  }
}
