:root {
  --color-text: #2a2828;
  --color-text-light: #444;
  --color-text-bright: #fff;
  --color-primary: #ada82b;
  --color-secondary: #218c74;
  --color-background: #ada82b;
  --color-icon: #666;

  --color-disabled: #7eb4d2;
  --color-success: #01916d;
  --color-success-back: #00bc8c;
  --color-info: #2b7cb3;
  --color-info-back: #3598db;
  --color-warning: #f0932b;
  --color-warning-back: #d68529;
  --color-danger: #ea2027;
  --color-danger-back: #d68529;
  --color-error: #ea2027;
  --color-error-back: #ff5d6c;

  --color-textfield-border: #ddd;
  --color-border: #000;

  --font-navbar: 'Anton', sans-serif;
  --font-text: 'Ibarra Real Nova', serif;
  --font-size-sm: 0.8rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.5rem;
  --font-size-vlg: 2rem;

  --radius: 10px;
  --base-size: 16px;
  --transition: all 0.5s;
}
