.post {
  width: 400px;
  margin: 0 25px 40px 25px;

  &__image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: var(--radius);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__category {
    font-size: var(--font-size-sm);
    color: var(--color-primary);
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
  }

  &__title {
    font-size: var(--font-size-lg);
    font-weight: 700;
    margin-top: 5px;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }

  &__date {
    font-size: 13px;
    font-style: italic;
    margin-top: 15px;
    color: var(--color-text-light);
  }

  &__description {
    font-size: var(--font-size-md);
    color: var(--color-text-light);
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
