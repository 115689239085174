.add-post-page {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 70vw;
    height: 250px;
    border-radius: var(--radius);
    object-fit: cover;
  }

  &__form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }

  &__add-file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(var(--font-size-md) * 1.2);
  }

  &__category-label {
    font-size: calc(var(--font-size-md) * 1.2);
    margin-bottom: 10px;
  }

  &__form-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid var(--color-border);
    font-size: var(--font-size-md);
    color: var(--color-icon);
    cursor: pointer;
    margin-top: 10px;
  }

  &__form-input {
    font-size: var(--font-size-vlg);
    border: none;
    padding: 20px;
    width: 70vw;

    &:focus {
      outline: none;
    }
  }

  &__form-input--textarea {
    height: 20vh;
    font-size: 20px;
    border: 1px solid var(--color-border);
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__submit-btn {
    background-color: var(--color-primary);
    padding: 10px;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-size: var(--font-size-md);
    font-weight: bold;
  }
}
