.login-page {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/login.jpg');
  background-size: cover;

  &__title {
    font-size: calc(var(--font-size-vlg) * 1.8);
    color: var(--color-text-bright);
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    & > label {
      margin: 10px 0;
      color: var(--color-text-bright);
      font-weight: bold;
    }
  }

  &__form-input {
    background-color: var(--color-background);
    padding: 10px;
    border: none;
  }

  &__form-login-btn {
    margin: 20px 0 40px 0;
    cursor: pointer;
    background-color: var(--color-primary);
    border: none;
    color: var(--color-text);
    border-radius: var(--radius);
    padding: 10px;
    font-weight: bold;

    &:disabled {
      background-color: var(--color-disabled);
      cursor: not-allowed;
    }
  }

  &__form__register-btn {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: var(--color-secondary);
    cursor: pointer;
    border: none;
    color: var(--color-text);
    border-radius: var(--radius);
    padding: 10px;
    font-weight: bold;

    & > a {
      color: var(--color-text);
    }
  }
  &__error {
    position: absolute;
    padding: 10px;
    bottom: 240px;
    color: var(--color-text);
    background-color: var(--color-warning);
    font-size: calc(var(--base-size));
    border-radius: var(--radius);
    font-weight: bold;
  }
}
