@import './settings.scss';
@import './typography.scss';
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-text);
  font-size: var(--base-size);
}

a,
a:hover,
a:visited {
  text-decoration: none;
}
