.header {
  &__titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-text);
    color: var(--color-text-light);
  }

  &__title {
    position: absolute;
    top: 8%;
    font-size: calc(var(--font-size-vlg) * 3);
  }

  &__image {
    width: 100%;
    height: 450px;
    margin-top: 80px;
    object-fit: cover;
  }
}
