.social-icons {
  display: flex;
  justify-content: center;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    border: 3px solid var(--color-icon);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: var(--transition);
    color: var(--color-icon);
    margin: 0 5px;
  }

  &__twitter:hover {
    background-color: #4099ff;
    border: 3px solid #4099ff;
    color: #fff;
  }

  &__facebook:hover {
    background-color: #3b5998;
    border: 3px solid #3b5998;
    color: #fff;
  }

  &__pinterest:hover {
    background-color: #cb2028;
    border: 3px solid #cb2028;
    color: #fff;
  }
}
