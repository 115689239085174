.navbar {
  font-family: var(--font-navbar);
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  z-index: 999;

  &__left,
  &__right {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__center {
    flex: 6;
  }

  &__center-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__center-list-item {
    font-size: calc(var(--font-size-md) * 1.4);
    margin-right: 20px;
    cursor: pointer;
    text-transform: uppercase;
  }

  &__right-image {
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
  }
}
